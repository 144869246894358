@media (min-width: 500px) {
  .map-logo-control {
    visibility: hidden;
  }
}

.logo-pin {
  display: grid;
  position: relative;
  font-size: 16px;
  filter: drop-shadow(0px 1.5px 12px rgba(0, 0, 0, 0.2));
  box-sizing: border-box;
}

/* Make only the contents of the pin clickable */
.logo-pin {
  pointer-events: none !important;
}

.logo-pin * {
  pointer-events: auto;
}

.logo-pin:hover {
  z-index: 10000;
}

.logo {
  z-index: 0;
  justify-self: center;
  display: block;
  transform: scale(0.5) translateY(35px);
  width: 80px;
  height: 80px;
  background-color: white;
}

/*
.logo-pin div {
  z-index: 0;

  justify-self: center;
  display: block;

  box-sizing: border-box;
  border: 1px solid rgba(15, 23, 42, 0.15);
  border-radius: 48px;
  filter: drop-shadow(0px 1.5px 12px rgba(0, 0, 0, 0.2));
  background-color: white;

  width: 80px;
  height: 80px;
  transform: scale(0.5) translateY(35px);
}
*/

.pay {
  z-index: 1;

  justify-self: center;
  margin-top: -0.5rem;

  box-sizing: border-box;
  border: 1px solid var(--gray-200, #dedfe3);
  border-radius: 2rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  background-color: white;

  font-size: 0.75rem;
  font-weight: 400;
  padding: 2px 4px;

  color: #101222;
}

.logo-pin.card-hover .pay {
  border-color: rgba(20, 20, 20, 1);
}

.selected-pin {
  z-index: 10000;
}

.logo-pin.selected-pin .pay {
  color: white;
  background-color: rgba(20, 20, 20, 1);
  font-weight: 500;
  padding: 4px 6px;
  border-color: rgba(20, 20, 20, 0.5);
}

.logo-pin.card-hover .logo {
  outline: 4px solid var(--green-outline);
  transform: scale(0.6) translateY(25px);
}

.logo-pin.selected-pin {
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.89));
}

.logo-pin.selected-pin .logo {
  outline: 6px solid var(--mantine-color-brand-4);
  transform: scale(0.8) translateY(15px);
}

.cluster-pin {
  height: 1rem;
  font-weight: 500;
  line-height: 0.75;
  text-align: center;
  font-size: 10px;
  padding: 4px;
  border-radius: 28px;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid var(--mantine-color-brand-6, #b4fa50);
  background: var(--mantine-color-brand-4, #e8fdca);
  /* Map Shadow */
  box-shadow:
    0px 2px 8px 0px rgba(16, 18, 34, 0.1),
    0px 2px 2px 0px rgba(16, 18, 34, 0.1),
    0px 1px 1px 0px rgba(16, 18, 34, 0.1);
}
